export const Dialogues = [
  [
    ["55%", "80%", "More than 5.5 lakh IT employees in India"],
    ["25%", "10%", "Fifth largest economy in the world"],
    ["50%", "70%", "Becoming 3rd largest economy in the world soon"],
    ["78%", "30%", "Becoming a developed nation by 2047"],
    ["55%", "80%", "140 crore proud Indians, with 800m mobile subscribers"],
    ["25%", "70%", "India has the lowest price for internet data in the world"],
    ["75%", "10%", "Startup capital of the world"],
    ["33%", "70%", "Digital India, the most successful fintech in the world"],
    [
      "25%",
      "10%",
      "We’re the youngest country with more than 50% of our population at the age of 25",
    ],
    ["50%", "70%", "The most stable country in today’s uncertain world"],
    ["78%", "30%", "More than 6.64 lakhs villages in India"],
  ],
  [
    ["75%", "5%", "More than 5.5 lakh IT employees in India"],
    ["15%", "5%", "Fifth largest economy in the world"],
    ["75%", "5%", "Becoming 3rd largest economy in the world soon"],
    ["15%", "20%", "Becoming a developed nation by 2047"],
    ["70%", "5%", "140 crore proud Indians, with 800m mobile subscribers"],
    ["15%", "5%", "India has the lowest price for internet data in the world"],
    ["75%", "5%", "Startup capital of the world"],
    ["15%", "20%", "Digital India, the most successful fintech in the world"],
    [
      "70%",
      "5%",
      "We’re the youngest country with more than 50% of our population at the age of 25",
    ],
    ["12%", "10%", "The most stable country in today’s uncertain world"],
    ["72%", "30%", "More than 6.64 lakhs villages in India"],
  ],
];

// The Length should be the same for Mob and Desktop arrays
