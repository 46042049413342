import React from "react";
import { useState, useEffect, useRef } from "react";
import "./Homepage.css";
import Person from "../components/Person/Person";
// import BoloIcon from "../assets/BoloIcon.svg";
import BoloImage from "../assets/BoloImage.svg";
import BoloBack from "../assets/BoloBack.png";
import EnglishMain from "../assets/EnglishMain.svg";
import HindiMain from "../assets/HindiMain.svg";
import KannadaMain from "../assets/KannadaMain.svg";
import TamilMain from "../assets/TamilMain.svg";
import TeluguMain from "../assets/TeluguMain.svg";
import BengaliMain from "../assets/BengaliMain.svg";
import MalayalamMain from "../assets/MalayalamMain.svg";
import MarathiMain from "../assets/MarathiMain.svg";
import EnglishMain1 from "../assets/English1Main.svg";
import EnglishSub from "../assets/EnglishSub.svg";
import PunjabiMain from "../assets/PunjabiMain.svg";
import GujaratiMain from "../assets/GujaratiMain.svg";
import AssameseMain from "../assets/AssameseMain.svg";
import Header from "../Header/Header";
import { Dialogues } from "../components/Dialogue/Dialogue";
import Map from "../components/Map/Map";

const Homepage = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isTextVisible, setIsTextVisible] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const imageRef = useRef(null);
  const images = [
    EnglishMain,
    EnglishMain1,
    HindiMain,
    KannadaMain,
    TeluguMain,
    TamilMain,
    MalayalamMain,
    MarathiMain,
    GujaratiMain,
    PunjabiMain,
    BengaliMain,
    AssameseMain,
  ];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMap(true);
    }, 700);

    // Cleanup the timer if the component unmounts
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const startTimer = setTimeout(() => {
      setIsTextVisible(true);
      const interval = setInterval(() => {
        const Image = document.querySelector(".Main_Text");
        if (Image) {
          Image.classList.add("add");
          setTimeout(() => {
            Image.classList.remove("add");
          }, 1000);
        }
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 2400);

      return () => clearInterval(interval);
    }, 10000);

    return () => clearTimeout(startTimer);
  }, [images.length]);
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      });
    });

    const currentImageRef = imageRef.current;

    if (currentImageRef) {
      observer.observe(currentImageRef);
    }

    return () => {
      if (currentImageRef) {
        observer.unobserve(currentImageRef);
      }
    };
  }, []);
  const imageRef1 = useRef(null);
  const [timerStarted, setTimerStarted] = useState(false);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setTimerStarted(false);
      } else {
        setTimerStarted(true);
      }
    }, options);
    const currentImageRef = imageRef.current;

    if (currentImageRef) {
      observer.observe(currentImageRef);
    }

    return () => {
      if (currentImageRef) {
        observer.unobserve(currentImageRef);
      }
    };
  }, []);

  useEffect(() => {
    let timer;
    let count = 1;
    const section = document.getElementById("Animation_Section");
    if (timerStarted) {
      timer = setInterval(() => {
        if (count === 36) {
          section.scrollIntoView({ behavior: "smooth" });
        }
        count++;
      }, 1000);
    } else {
      clearInterval(timer);
    }

    return () => clearInterval(timer);
  }, [timerStarted]);

  return (
    <div>
      <Header />
      <div className="Homepage_Section">
        <div className="Snap_Section">
          <div
            className="Image_Section"
            style={{ backgroundImage: `url(${BoloBack})` }}
          >
            <div className="Image_Sub_Section">
              <div className="Image_Sub_Section_Map" ref={imageRef1}>
              {showMap && <Map />}
              </div>
              <div className="Bolo_Icon_Container">
                {/* <img className="Main_Logo" src={BoloIcon} alt="BoloIndia" /> */}
                <img className="Main_Logo" src={BoloImage} alt="BoloIndia" />
                <img
                  className={`Main_Text ${
                    isTextVisible ? "visible" : "hidden"
                  }`}
                  src={images[currentImageIndex]}
                  alt="BoloIndia"
                />
              </div>
            </div>
            <div className="Snap_Section">
              <div className="Image_Sub_Section">
                <div id="Animation_Section" className="Bolo_Icon_Container">
                  <div className="Images_Container">
                    <img
                      ref={imageRef}
                      className={`Main_Logo_1 ${isVisible ? "active" : ""}`}
                      src={BoloImage}
                      alt="BoloIndia"
                    />
                    <img
                      className={`Sub_Text ${isVisible ? "active" : ""}`}
                      src={EnglishSub}
                      alt="BoloIndia"
                    />
                  </div>
                  <Person positions={Dialogues} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="Snap_Section">
          <div className="Content_Section">
            <div className="Bolo_Box">
              <div className="Bolo_Text_Container">
                <div className="Bolo_Container_T">
                  <h2>Made In India</h2>
                  <h3>
                    We know you’re on many platforms. Try us out! It’s a genuine
                    effort to connect with Indians on everything that matters to
                    you in India, from every nukkad & gully.
                  </h3>
                  <p>Made in India, this is your platform!</p>
                </div>
              </div>
              <div className="Bolo_Image_Container">
                <img src={Bolo} alt="BoloIndia" />
                <p>Hey, Bolo</p>
              </div>
            </div>
            <div className="Bolo_Box">
              <div className="Bolo_Image_Container">
                <img src={Bolo} alt="BoloIndia" />
                <p>Verified Profiles</p>
              </div>
              <div className="Bolo_Text_Container">
                <div className="Bolo_Container_T">
                  <h2>Genuine! No fakes</h2>
                  <h3>
                    There is clutter everywhere. We want to keep Bolo India
                    clean & tidy with genuine Indians on it minus the fake ones.
                  </h3>
                  <p>We authenticate you with your Aadhar.</p>
                </div>
              </div>
            </div>
            <div className="Bolo_Box">
              <div className="Bolo_Text_Container">
                <div className="Bolo_Container_T">
                  <h2>Our Children</h2>
                  <h3>
                    We love our children, & want to ensure their childhood
                    remains pristine. You can sign up with Bolo India as student
                    with access to everything that matters to you, minus the
                    ones that affects you.
                  </h3>
                  <p>Your college ID gets you in.</p>
                </div>
              </div>
              <div className="Bolo_Image_Container">
                <img src={Bolo} alt="BoloIndia" />
                <p>Student Content</p>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Homepage;
