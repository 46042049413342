import React, { useState, useEffect } from "react";
import "./Map.css";
import Box from "../../assets/Box.svg";
import Imap from "../../assets/Imap.svg";
import AP from "../../assets/INAP.svg";
import AR from "../../assets/INAR.svg";
import AS from "../../assets/INAS.svg";
import BR from "../../assets/INBR.svg";
import CH from "../../assets/INCH.svg";
import CT from "../../assets/INCT.svg";
import DH from "../../assets/INDH.svg";
import DL from "../../assets/INDL.svg";
import GA from "../../assets/INGA.svg";
import GJ from "../../assets/INGJ.svg";
import HP from "../../assets/INHP.svg";
import HR from "../../assets/INHR.svg";
import JH from "../../assets/INJH.svg";
import JK from "../../assets/INJK.svg";
import KA from "../../assets/INKA.svg";
import KL from "../../assets/INKL.svg";
import LA from "../../assets/INLA.svg";
import MH from "../../assets/INMH.svg";
import ML from "../../assets/INML.svg";
import MN from "../../assets/INMN.svg";
import MP from "../../assets/INMP.svg";
import MZ from "../../assets/INMZ.svg";
import NL from "../../assets/INNL.svg";
import OR from "../../assets/INOR.svg";
import PB from "../../assets/INPB.svg";
import PY from "../../assets/INPY.svg";
import RJ from "../../assets/INRJ.svg";
import SK from "../../assets/INSK.svg";
import TG from "../../assets/INTG.svg";
import TN from "../../assets/INTN.svg";
import TR from "../../assets/INTR.svg";
import UP from "../../assets/INUP.svg";
import UT from "../../assets/INUT.svg";
import WB from "../../assets/INWB.svg";
import FinalMap from "../../assets/NEW MAP1.svg";

const Map = () => {
  const [showFinalMap, setShowFinalMap] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    const fadeTimer = setTimeout(() => {
      setFadeOut(true);
    }, 9300);

    const showFinalMapTimer = setTimeout(() => {
      setShowFinalMap(true);
    }, 9300);

    return () => {
      clearTimeout(fadeTimer);
      clearTimeout(showFinalMapTimer);
    };
  }, []);

  return (
    <div>
     
      <div className={`Map_States ${fadeOut ? "hidden" : ""}`}>
        <img className="Box" src={Box} alt="Border" />
        <img className="CT" src={CT} alt="State" />
        <img className="DH" src={DH} alt="State" />
        <img className="JK" src={JK} alt="State" />
        <img className="LA" src={LA} alt="State" />
        <img className="PB" src={PB} alt="State" />
        <img className="HP" src={HP} alt="State" />
        <img className="UT" src={UT} alt="State" />
        <img className="HR" src={HR} alt="State" />
        <img className="UP" src={UP} alt="State" />
        <img className="DL" src={DL} alt="State" />
        <img className="RJ" src={RJ} alt="State" />
        <img className="GJ" src={GJ} alt="State" />
        <img className="MP" src={MP} alt="State" />
        <img className="MH" src={MH} alt="State" />
        <img className="GA" src={GA} alt="State" />
        <img className="KA" src={KA} alt="State" />
        <img className="KL" src={KL} alt="State" />
        <img className="TN" src={TN} alt="State" />
        <img className="AP" src={AP} alt="State" />
        <img className="TG" src={TG} alt="State" />
        <img className="CH" src={CH} alt="State" />
        <img className="OR" src={OR} alt="State" />
        <img className="JH" src={JH} alt="State" />
        <img className="BR" src={BR} alt="State" />
        <img className="WB" src={WB} alt="State" />
        <img className="SK" src={SK} alt="State" />
        <img className="AR" src={AR} alt="State" />
        <img className="AS" src={AS} alt="State" />
        <img className="NL" src={NL} alt="State" />
        <img className="MN" src={MN} alt="State" />
        <img className="MZ" src={MZ} alt="State" />
        <img className="TR" src={TR} alt="State" />
        <img className="ML" src={ML} alt="State" />
        <img className="PY" src={PY} alt="State" /> 
      </div>
      <img
        className={`Final_Map ${showFinalMap ? "visible" : ""}`}
        src={FinalMap}
        alt="India"
      />
    </div>
  );
};

export default Map;
