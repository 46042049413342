import React, { useState, useEffect, useRef } from "react";
import People from "../../assets/Person.svg";
import "./Person.css";
import Tick from "../../assets/Tick.svg";
import axios from "axios";
import { BaseURL } from "../../BaseURL";

const Person = ({ positions }) => {
  const [formValues, setFormValues] = useState({
    Email: "",
  });
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };
  const [styleIndex, setStyleIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const imageRef = useRef(null);
  const [toggle, setToggle] = useState(true);
  const [error, setError] = useState(false);
  const [subscribe, setSubscribe] = useState(false);

  useEffect(() => {
    let currentIndex = 0;
    if (!isVisible) {
      return;
    }
    const interval = setInterval(() => {
      if (currentIndex < positions[1].length) {
        const people = document.querySelector(".Person");
        const message = document.querySelector(".Message_Box");

        people.classList.add("active1");
        message.classList.add("active1");
        setStyleIndex((prevIndex) => (prevIndex + 1) % positions[1].length);
        people.classList.remove("deactive");
        message.classList.remove("deactive");

        setTimeout(() => {
          people.classList.remove("active1");
          message.classList.remove("active1");
        }, 1000);

        currentIndex++;
      } else {
        setIsVisible(false);
        setToggle(true);
      }
    }, 3000);

    return () => clearInterval(interval);
  }, [positions, setStyleIndex, isVisible]);

  const width = window.innerWidth;
  const index = width <= 768 ? false : true;
  let currentTop, currentLeft, dialogues;

  if (index) {
    [currentTop, currentLeft, dialogues] = positions[0][styleIndex];
  } else {
    [currentTop, currentLeft, dialogues] = positions[1][styleIndex];
  }

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      });
    });

    const currentImageRef = imageRef.current;

    if (currentImageRef) {
      observer.observe(currentImageRef);
    }

    return () => {
      if (currentImageRef) {
        observer.unobserve(currentImageRef);
      }
    };
  }, []);

  const handleEmail = async () => {
    if (
      [formValues.Email].some(
        (field) => field?.trim() === "" || !emailRegex.test(field)
      )
    ) {
      setError(true);
      return;
    } else {
      await axios.post(`${BaseURL}/api/v1/users/SubscribeForm`, formValues, {
        header: {
          "content type": "application.json",
        },
      });
      setFormValues({
        Email: "",
      });
      setError(false);
      setSubscribe(true);
    }
  };

  return (
    <div>
      <div
        ref={imageRef}
        className={`Person_Box ${isVisible ? "active" : ""}`}
        style={{ top: currentTop, left: currentLeft }}
      >
        <div className="Message_Box">
          <p>{dialogues}</p>
        </div>
        <img className="Person" src={People} alt="Bolo India" />
      </div>
      {toggle && (
        <>
          {subscribe ? (
            <>
              <div
                className="Bolo_Subscribe"
                style={{
                  width: "auto",
                  flexDirection: "column-reverse",

                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <p style={{ color: "#0fba00" }}>
                  <strong>You're&nbsp;Subscribed</strong>
                </p>
                <img src={Tick} alt="Sunscribed" />
              </div>
            </>
          ) : (
            <>
              <div className="Bolo_Subscribe">
                <p>
                  Coming Soon...{" "}
                  <strong>Subscribe&nbsp;to&nbsp;Bolo&nbsp;India.</strong>
                </p>
                <div className="Subscribe_Button">
                  <input
                    type="email"
                    name="Email"
                    value={formValues.Email}
                    onChange={handleChange}
                    placeholder="Enter your E-mail"
                    required
                  />
                  <button onClick={handleEmail}>Send</button>
                </div>
                {error && (
                  <>
                    <h6>Please enter valid E-mail ID</h6>
                  </>
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Person;
